<template>
  <div class="my-course-order-detail">
    <div class="kcxx-box">
      <courseInfo :detail="courseDetail" :form="form" show-flag="ddxq" />
    </div>
    <div class="footer-info-btn">
      <div class="button qxbm-btn" :class="{ disabled: !(courseDetail.payType === 1 && courseDetail.applyStatus === 1) }" @click="cancelApplyOnClick">
        取消报名
      </div>
    </div>
  </div>
</template>

<script>
import { getMyCourseOrderDetailUrl, cancelMyCourseOrderPayApplyUrl } from '../api.js';
import { courseOrderStatusMap } from '../map.js';
import courseInfo from '../components/courseInfo.vue';
import { mapState } from 'vuex';

export default {
  name: 'myCourseOrderDetail',
  components: { courseInfo },
  data() {
    return {
      courseDetail: {
        courseName: '',
        courseSettingList: [],
        courseOpenStart: '',
        courseOpenEnd: '',
        applyDateStart: '',
        applyDateEnd: '',
        teacher: '',
        isAstrictPersons: void 0,
        minPerson: void 0,
        maxPerson: void 0,
        courseAddr: '',
        courseAdmin: '',
        courseAdminMobile: '',
        price: void 0,
        chargeTypeText: '',
        payType: void 0,
        applyStatus: void 0,
        applyStatusText: void 0
      },
      form: {
        userName: '',
        mobileNum: ''
      },
      timer: null,
      currentDate: new Date()
    };
  },
  computed: {
    ...mapState(['userId'])
  },
  created() {
    this.getDetail();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getDetail() {
      this.$axios
        .get(getMyCourseOrderDetailUrl, { params: { id: this.$route.query.id } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.chargeTypeText = '整期';
            res.data.applyStatusText = courseOrderStatusMap[res.data.applyStatus];
            this.form.userName = res.data.userName;
            this.form.mobileNum = res.data.mobileNum;
            Object.assign(this.courseDetail, res.data);
            this.form.payAmount = res.data.price;
          }
        })
        .catch(() => {});
    },
    cancelApplyOnClick() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定取消报名？',
          confirmButtonColor: '#3680FF'
          // beforeClose: (action, done) => {
          //   if (action === 'confirm') {
          //     this.cancelApplyHandle(done);
          //   } else {
          //     done();
          //   }
          // }
        })
        .then(() => {
          this.cancelApplyHandle();
        })
        .catch(() => {});
    },
    cancelApplyHandle(callback) {
      this.$axios
        .get(cancelMyCourseOrderPayApplyUrl, { params: { id: this.$route.query.id } })
        .then(res => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            callback && callback();
            this.$router.go(-1);
          } else {
            callback && callback(false);
          }
        })
        .catch(() => {
          callback && callback(false);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.my-course-order-detail {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  background-color: #fafafa;
  padding-bottom: 100px;
}
.kcxx-box {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.footer-info-btn {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  padding: 0px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  .button {
    box-sizing: border-box;
    width: 80%;
    height: 66px;
    border-radius: 10px;
    background: linear-gradient(180deg, #ed1d1d 0%, #ef6565 100%);
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    font-size: 30px;
    line-height: 42px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    &.disabled {
      color: #a8a8a8;
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      pointer-events: none;
    }
  }
}
</style>
